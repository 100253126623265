import React from "react"
import { H2, H5, buttonBckg } from "../../globalStyles"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styled from "styled-components"

function Phases(props) {
  const { phase, title, dark, about, bullets, open } = props
  //

  return (
    <ColMin
      ref={props.myRef}
      open={open}
      className="col-12 col-md-6 mt-5 col-lg-3 text-left "
      about={about}
    >
      {about && (
        <H5
          className="mb-1"
          top
          landing
          style={{
            color: dark ? buttonBckg.darkHover : "white",
          }}
        >
          {phase}
        </H5>
      )}
      <H2
        about={"true"}
        dark={dark}
        values={"true"}
        className="mb-3"
        top
        landing
        style={{
          color: dark ? buttonBckg.darkHover : "white",
        }}
      >
        {title}
      </H2>

      {bullets && (
        <UnorderedList dark={dark}>
          {documentToReactComponents(JSON.parse(bullets.raw))}
        </UnorderedList>
      )}
    </ColMin>
  )
}

export default Phases

export const ColMin = styled.div`
  transition: all 300ms;
  height: ${({ open }) => (open ? `auto` : `1px`)};

  @media (max-width: 640px) {
    margin: 5% 0 0 0;

    ${({ top }) => top && `margin-bottom:5%; margin-top:10%;`}
  }

  @media (min-width: 641px) {
    ${({ top }) => top && `margin-top:10%;`}
    margin-bottom:25px;
  }

  @media (min-width: 1024px) {
    ${({ top }) => top && `margin-top:3%; margin-bottom:3%;`}
    ${({ top }) => !top && `margin-top:2%; `}
  }

  ${({ about }) => about && `opacity:0;`}
`

const UnorderedList = styled.div`
  margin-left: 10px;
  display: block;
  position: relative;
  * {
    font-weight: 300;
    @media (max-width: 640px) {
      font-size: 1rem;
    }

    @media (min-width: 641px) {
      font-size: 1.2rem;
      line-height: 2;
    }
    color: ${({ dark }) => (dark ? "#505050" : "white")};
  }
`
