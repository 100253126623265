import React, { useContext, useEffect, useRef } from "react"
import { ThemeContext } from "../../contexts/ThemeContext"

import Phases from "./Phases"
import { H1, H3, buttonBckg, Row, StarsSix } from "../../globalStyles"
import { FullContainerLocal, ColMin, Planets } from "./AboutStyle"

import Footer from "../HomeSuccessSection/CustomFooter"

import { noTriggerFx } from "../GSAP/gsap"
function About({ data }) {
  const { isDarkMode } = useContext(ThemeContext)

  let items = data.allContentfulAboutMain.nodes[0]

  let phasesArr = data.allContentfulAboutPhases.nodes
  ///
  const serviceRef = useRef(null)
  serviceRef.current = []
  const addToRefs = el => {
    if (el && !serviceRef.current.includes(el)) {
      serviceRef.current.push(el)
    }
  }
  useEffect(() => {
    if (serviceRef.current.length === phasesArr.length) {
      noTriggerFx(serviceRef.current, 200, 0, 0)
    }
    // console.log()

    return () => {}
  }, [phasesArr])
  ////

  let { para, svg, title, paraTwo } = items

  let phases = phasesArr.map((x, i) => (
    <Phases
      myRef={addToRefs}
      key={i}
      about={"true"}
      phase={x.phaseNumber}
      title={x.phaseTitle}
      bullets={x.phaseBullets}
      dark={isDarkMode}
      open={true}
    />
  ))

  return (
    <FullContainerLocal dark={isDarkMode} className="container-fluid">
      {/* <Planets dark src={mainValue[0].mainSvg[0].file.url} /> */}

      <Planets
        dark={isDarkMode}
        neptune
        alt={svg[0].title}
        src={svg[0].file.url}
      />

      <Planets saturn alt={svg[1].title} src={svg[1].file.url} />

      {!isDarkMode && (
        <>
          <StarsSix /> <StarsSix bottom />
        </>
      )}
      <Row>
        <div className="row justify-content-center text-center align-items-center">
          <ColMin
            small
            top
            className="col-11 col-md-8 col-lg-7   align-self-center"
          >
            <H1
              // landing
              style={{
                color: isDarkMode ? buttonBckg.darkHover : "white",
              }}
            >
              {title}
            </H1>
            <H3
              className="mt-5"
              style={{ color: isDarkMode ? "#505050" : "white" }}
            >
              {para.para}
            </H3>
            <H3
              className="mt-5"
              style={{ color: isDarkMode ? "#505050" : "white" }}
            >
              {paraTwo.paraTwo}
            </H3>
          </ColMin>
        </div>
        <div
          style={{
            width: "95%",
            background: isDarkMode ? "rgba(255,255,255,.4)" : null,
            borderRadius: "15px",
          }}
          className="row justify-content-center mb-5   mr-auto ml-auto mt-4  text-center align-items-top align-self-center"
        >
          {phases}
        </div>
        <Footer footer={"true"} dark={isDarkMode} />
      </Row>
    </FullContainerLocal>
  )
}

export default About
