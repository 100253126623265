import React from "react"
import Layout from "../components/Layout"
import AboutComponent from "../components/About/About"
import { graphql, useStaticQuery } from "gatsby"
import HelmetComponent from "../components/Helmet"
import useIsClient from "../hooks/rehydration"
// import Loader from "../components/Loader/Loader"

function About() {
  const { isClient } = useIsClient()
  const data = useStaticQuery(graphql`
    {
      allContentfulSeoEach(
        sort: { fields: createdAt, order: ASC }
        filter: { node_locale: { eq: "hu" } }
      ) {
        edges {
          node {
            id
            title
            url
            description
            facebookImage {
              fixed {
                srcWebp
              }
            }
          }
        }
      }
      allContentfulAboutMain(filter: { node_locale: { eq: "hu" } }) {
        nodes {
          para {
            para
          }
          paraTwo {
            paraTwo
          }
          svg {
            title
            file {
              url
            }
          }
          title
        }
      }
      allContentfulAboutPhases(
        sort: { fields: createdAt }
        filter: { node_locale: { eq: "hu" } }
      ) {
        nodes {
          phaseNumber
          phaseTitle
          phaseBullets {
            raw
          }
        }
      }
    }
  `)
  let seo = data.allContentfulSeoEach.edges[1].node
  if (!isClient) return <HelmetComponent seo={seo} />
  return (
    <Layout>
      <HelmetComponent seo={seo} />
      <AboutComponent data={data} />
    </Layout>
  )
}

export default About
